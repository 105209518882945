import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SimilarProduct.css';  // Ensure you add the necessary styles

const SimilarProductModal = ({ showSimilarModal, product, onClose,addProductToComparison }) => {
  const [infoo, setInfoo] = useState([]);  // State for similar products
  const [loading, setLoading] = useState(true);  // Loading state for fetching data
  const [error, setError] = useState(null);  // Error state for API errors
  const [isComparing, setIsComparing] = useState(false);  // State to manage button disable
  const [comparisonList, setComparisonList] = useState(
    JSON.parse(sessionStorage.getItem('comparisonList')) || []
  );
  const [addProductMessage, setAddProductMessage] = useState('');  // Message state for product added

  // Update sessionStorage with the current comparison list
  useEffect(() => {
    sessionStorage.setItem('comparisonList', JSON.stringify(comparisonList));
  }, [comparisonList]);
console.log( 'ppppppppppppp',JSON.parse(sessionStorage.getItem('comparisonList')));
  useEffect(() => {
    // Early exit if modal isn't showing
    if (!showSimilarModal) return;

    // Fetch similar products only when the modal is visible
    setLoading(true);  // Start loading state
    setError(null);    // Reset error before making a new request

    axios
      .post('https://midbserver.co.in:8289/trading_application/rest/menuService/getSimilarProducts', {
        one: product.btp_product_id,  // Pass the actual product ID
        authApiKey: 'ytfkenaojjawmbjnbsyyj-vhvbs',  // Correct API key
      })
      .then((res) => {
        if (res.data && Array.isArray(res.data.jsArray)) {
          setInfoo(res.data.jsArray);  // Assuming res.data.jsArray contains the list of similar products
        } else {
          setError('Expected data not found');
        }
      })
      .catch((err) => {
        setError('There was an error: ' + (err.response ? err.response.data : err.message));
      })
      .finally(() => {
        setLoading(false);  // Set loading to false once the request is completed
      });
  }, [showSimilarModal, product.btp_product_id]);

  const handleViewProductClick = (product) => {
    // Handle the product view click (maybe navigate to a detailed page or show product details)
    console.log('Viewing product:', product);
  };

//   const handleAddToComparison = (id) => {
//     if (comparisonList.length >= 20) {
//       alert('You can only compare up to 20 products.');
//       return;
//     }

//     // Add the product to the comparison list
//     const newComparisonList = [...comparisonList, id];
//     setComparisonList(newComparisonList);

//     // Set a message to show the product was added
//     setAddProductMessage('Product added to comparison');

//     // Remove the message after 2 seconds
//     setTimeout(() => {
//       setAddProductMessage('');
//     }, 2000); // Message will disappear after 2 seconds
//   };
const handleAddToComparison = (id) => {
    addProductToComparison(id); // Add the similar product to the comparison list
  };

  // Don't render modal content if not visible
  if (!showSimilarModal) return null;

  // Render loading and error states
  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="modal-overlay similar active">
      <div className="modal-content similar">
        <span className="modal-close-button" onClick={onClose}>×</span>
        <h4 style={{ textAlign: "center", color: "#0d778e", fontWeight: "700" }}>
          Similar Products for {product.btp_product_name}
        </h4>

        <div className="row">
          {infoo.map((similarProduct, index) => (
            <div key={index} className="col-12 col-lg-4 col-md-6 col-sm-12 mb-4 my-2">
              <div
                className="box"
                style={{
                  width: "13rem",
                  display: "flex",
                  flexDirection: "column",
                  background: "lavender",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <img
                  style={{ height: "100px", width: "100px" }}
                  src={
                    similarProduct.btp_image1
                      ? `https://midbserver.co.in:8289/MI_Applications/BTrading/BrandLogo/2024/December/${similarProduct.btp_image1}`
                      : 'https://media.istockphoto.com/id/182691828/photo/bathroom-faucet.jpg?s=612x612&w=0&k=20&c=4erUeHXd06v50a9z6IPxx3LqG7oO6R2cig9hSaLPalI='
                  }
                  className="card-img-top"
                  alt={similarProduct.series_name}
                />
                <p
                  className="card-text"
                  style={{
                    fontSize: '20px',
                    color: "#0d778e",
                    fontWeight: "700",
                    cursor: "pointer",
                    whiteSpace: "nowrap", // Prevent text from wrapping
                    overflow: "hidden", // Hide the overflow text
                    textOverflow: "ellipsis", // Add ellipsis (...) at the end
                    maxWidth: "180px", // Set a max width based on your design needs
                    display: "inline-block", // Keep the element inline for truncation
                  }}
                  title={similarProduct.btp_product_name}
                >
                  {similarProduct.btp_product_name}
                </p>
                <p style={{ color: "black", fontSize: "13px", marginBottom: "0px" }}>
                  <small>Brand: {similarProduct.btb_brand_name}</small>
                </p>
                <p style={{ color: "black", fontSize: "13px", marginBottom: "0px" }}>
                  <span>Price: &nbsp;{similarProduct.btp_mrp_price}</span>
                </p>
                <p style={{ color: "black", marginBottom: "0px", fontSize: "13px" }}>
                  <b style={{ color: "gray", marginBottom: "0px", fontSize: "13px" }}>
                    Series: &nbsp;{similarProduct.bts_series_name || 'SA1254'}
                  </b>
                </p>

                <div style={{ marginTop: "5px", marginBottom: "0px", justifyContent: "center" }}>
                  <button
                    className="btn btn-outline-success btn-sm"
                    onClick={() => handleAddToComparison(similarProduct.btp_product_id)}
                    disabled={isComparing} // Disable the button while the request is in progress
                  >
                    Add To Quote
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Optional: Display the add to comparison message */}
        {addProductMessage && (
          <div style={{ position: 'absolute', top: '10px', right: '10px', background: '#28a745', color: 'white', padding: '5px 10px', borderRadius: '5px' }}>
            {addProductMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default SimilarProductModal;
