import React from 'react'
import { Link, Outlet,useNavigate } from 'react-router-dom'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser,faNoteSticky } from '@fortawesome/free-solid-svg-icons';
import './NavBar.css'
import Footer from './Footer'
import MenuBar from './MenuBar'
import logo from '../assets/img/We Are One Logo (1) (1).png'

export default function NavBar() {
  const navigate = useNavigate(); // Initialize the navigate function
  const handleLogout = () => {
    // Add any additional logout logic here (e.g., clearing sessionStorage or resetting authentication state)
    sessionStorage.removeItem('isAuthenticated'); // Assuming you are using sessionStorage to track authentication
    sessionStorage.removeItem('em_id');  // Remove the stored em_id
    navigate('/'); // Navigate to the login page
    window.location.reload();
  };


  return (
    <div><nav className="navbar navbar-expand-lg bg-body-light">
      <div className="container-fluid" style={{ marginTop: "0px" }}>
        <a className="navbar-brand" href="#">
          <img
            src={logo}
            width="80px"
            alt="Logo"
            className="logo"
            height="50px"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{color:"white"}}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
          <ul className="navbar-nav  gap-4" >
            <li className="nav-item">
              <Link to="/home" className="nav-link " aria-current="page">
                <b>Home</b>
              </Link>
            </li>
           
            <li className="nav-item">
              <Link to="/about" className="nav-link" aria-current="page">
                <b>About </b>
              </Link>
            </li>
       
            <li className="nav-item">
              <Link to="/cart" className="nav-link" aria-current="page">
                <b>Predefined Sets</b>
              </Link>
            </li>
            <li className="nav-item">
            <Link to="/jaq" className="nav-link">
              <b >Our Brands</b>
            </Link>
          </li>
            <li className="nav-item">
              <Link to={'/comparPro'} className="nav-link" aria-current="page">
                <b> View Comparision</b>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/cart" className="nav-link" aria-current="page">
                <b> <i class="bi bi-stickies-fill"></i>Quotation</b>
              </Link>
            </li>
            <li className="nav-item">
              <Link onClick={handleLogout} className="nav-link" aria-current="page">
                <b> Logout</b>
              </Link>
            </li>

          </ul>
        </div>
      </div>
    </nav>
      {/* <MenuBar/> */}
      <Outlet />
      <Footer />
    </div>
  )
}
