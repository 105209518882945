import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ProductModal from './ProductModal'; // Import the modal component

export default function Products(props) {
  const [products, setProducts] = useState([]); // State to hold the product data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [showModal, setShowModal] = useState(false); // State to show/hide the modal
  const [selectedProduct, setSelectedProduct] = useState(null); // State to hold the selected product details
  const [isComparing, setIsComparing] = useState(false); // Track if the comparison is being processed
  const [addProductMessage, setAddProductMessage] = useState('');
  const [alertType, setAlertType] = useState(''); // success, danger
  const [showAlert, setShowAlert] = useState(false); // Controls the visibility of the alert
  const [comparisonList, setComparisonList] = useState(
    JSON.parse(sessionStorage.getItem('comparisonList')) || []
  );

  useEffect(() => {
    sessionStorage.setItem('comparisonList', JSON.stringify(comparisonList));
  }, [comparisonList]);

  // Function to add a product to the comparison list
  const addProductToComparison = (productId) => {
    if (comparisonList.length >= 20) {
      setAddProductMessage('You can only compare up to 20 products.');
      setAlertType('danger'); // Bootstrap class for error
      setShowAlert(true);
      return;
    } else {
      setAddProductMessage('Product added to comparison');
      setAlertType('success'); // Bootstrap class for success
      setShowAlert(true);
    }

    setComparisonList((prevList) => [...prevList, productId]);
  };

  const itemId = sessionStorage.getItem('itemId');
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Start loading
        const res = await axios.post('https://midbserver.co.in:8289/trading_application/rest/menuService/getBrandProductDetails', {
          one: props.brandId, // Category ID
          two: itemId, // Brand ID
          authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
        });
        setProducts(res.data); // Set the fetched data to state
        setLoading(false); // End loading
      } catch (error) {
        setLoading(false); // End loading on error
        setError(error.message); // Set error message
        console.error(error);
      }
    };

    fetchData();
  }, [props.brandId]);

  const handleViewProductClick = (product) => {
    setSelectedProduct(product); // Set the selected product
    setShowModal(true); // Show the modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
    setSelectedProduct(null); // Clear selected product
  };

  // Auto dismiss alert after 3 seconds
  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false); // Hide the alert after 3 seconds
      }, 1500); // Set time duration for alert

      return () => clearTimeout(timer); // Cleanup the timer on component unmount or when showAlert changes
    }
  }, [showAlert]);

  if (loading) return <div>Loading products...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>

      <div className='container mt-1'>
     
      {products.length > 0 && props.brandName && (
  <h3 style={{ color: "rgb(255, 0, 79)", textAlign: "center" }}>
    Products for Category: {props.brandName}
  </h3>
)}
      
        {showAlert && (
          <div className='alert-container d-flex justify-content-center' >
          <div className={`alert alert-${alertType} alert fade show`} role="alert" style={{ }}>
            {addProductMessage}
          </div>
          </div>
        )}
     <div className='row'>
  {props.brandId && products.length === 0 ? (
    <div className='col-12'>
      <p style={{ textAlign: 'center', color: 'gray', fontSize: '16px' }}>
        No products available at the moment.
      </p>
    </div>
  ) : (
    products.map((product, index) => (
      <div key={index} className='col col-12 col-lg-3 col-md-4 col-sm-12 mb-4 my-2'>
        <div className="box" style={{ width: "13rem", display: "flex", flexDirection: "column", background: "lavender", padding: "10px", borderRadius: "10px" }}>
          <img
            style={{ height: "100px", width: "100px" }}
            src={product.btp_image1 ? `https://midbserver.co.in:8289/MI_Applications/BTrading/BrandLogo/2024/December/${product.btp_image1}` : 'https://media.istockphoto.com/id/182691828/photo/bathroom-faucet.jpg?s=612x612&w=0&k=20&c=4erUeHXd06v50a9z6IPxx3LqG7oO6R2cig9hSaLPalI='}
            className="card-img-top"
            alt={product.series_name}
          />
          <p className="card-text" style={{ fontSize: '15px', color: "#0d778e", fontWeight: "700", cursor: "pointer", marginBottom: "8px" }} title={product.btp_product_name}>{product.btp_product_name}</p>
          <p style={{ color: "black", fontSize: "13px", marginBottom: "8px" }}><small>{product.series_name}</small></p>
          <p style={{ color: "black", fontSize: "13px", marginBottom: "8px" }}><span>Price: &nbsp;{product.btp_mrp_price}</span></p>
          <p style={{ color: "black", fontSize: "13px", marginBottom: "8px" }}><b style={{ color: "gray" }}>Series:&nbsp;{product.bts_series_name || 'SA1254'}</b></p>

          <div style={{ marginTop: "5px", marginBottom: "0px", justifyContent: "center" }}>
            <button className="btn btn-outline-warning btn-sm" onClick={() => handleViewProductClick(product)} style={{ marginBottom: "3px" }}>View</button>
            &nbsp; &nbsp;
            <button className="btn btn-outline-success btn-sm" onClick={() => addProductToComparison(product.btp_product_id)} disabled={isComparing}>Add To Quote</button>
          </div>
        </div>
      </div>
    ))
  )}
</div>

      </div>

      {/* Product Modal */}
      <ProductModal 
        product={selectedProduct} 
        show={showModal} 
        onClose={handleCloseModal}
        comparisonList={comparisonList}
        addProductToComparison={addProductToComparison}
      />
    </>
  );
}
