import React, { useState, useEffect } from 'react';
import './ComparisionReport.css';
import axios from 'axios';
import { Link } from 'react-router-dom';


const ComparisionReport = () => { 
  const [infoo, setInfoo] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(false); // Track loading state
  const [message, setMessage] = useState(''); // State to store the message
  const [Report, setReport] = useState(''); // State to store the message    
  const [comparisonData, setComparisonData] = useState([]); // Store the API response data
  const [Clearmsg, setClearmsg] = useState('');     //clear session storage msg
  

  const ComparisionId = sessionStorage.getItem('ComparisionId');
console.log("ComparisionIddd",ComparisionId);            // This will log the saved QuoteId

  // Fetch series data
  const fetchData = () => {
      setLoading(true);
      axios.post('http://localhost:5000/proxy/getComparisionReportPoint', {
         // one:QuoteId,
          one:"324",
          authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
      })
          .then(res => {
              setInfoo(Array.isArray(res.data) ? res.data : []);
              setLoading(false);
              setReport(res.data);
            console.log(res.data);
            const data = res.data;
            setComparisonData(data); // Store the API response data
            console.log("comparisonData", res.data);
          })
          .catch(error => {
              setLoading(false);
              console.error('There was an error!', error);
          });
  };

  useEffect(() => {
      fetchData();
  }, []);

     
  // Helper function to group products by brand dynamically
  const groupProductsByBrand = (data) => {
    const brands = {};  // Use an empty object to store brands dynamically
  
    data.forEach((row) => {
      const brandName = row.brand_name ;  // Get brand name dynamically
      if (!brands[brandName]) {
        brands[brandName] = [];
      }

      brands[brandName].push({
        product: row.btp_product_name,
        productCode: row.btp_product_code_no,
        image: row.btp_image1,
        mrp: row.btp_mrp_price,
        discount: row.btp_max_discount,
        salePrice: row.sale_rate,
      });
    });
  
    return brands;
  };

  const groupedProducts = groupProductsByBrand(comparisonData);
  const brandNames = Object.keys(groupedProducts);  // Get all unique brand names
  console.log('groupedProducts', groupedProducts);

  return (
    <>
    <div className='container' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <p style={{ color: "#000000", textAlign: "center", flex: 1,fontSize:"23px" ,marginTop:"100px" }}>Quotation Master Report</p>
               
     </div>
      <div>

      

        {message && (
          <div style={{ marginTop: "10px", color: "green", fontWeight: "bold", textAlign: "center" }}>
            {message}
          </div>
        )}

        <div className="comparison-container">
          <table className="comparison-table" style={{ border: "2px solid #000",marginBottom:"20px"}}>
            <thead>
              <tr>
                {brandNames.map(brand => (
                  <th key={brand} colSpan="3" style={{ color: "rgb(13, 119, 142)", fontSize: "22px", borderRight: "1px solid #000" }}>
                    {brand.toUpperCase()}
                  </th>
                ))}
              </tr>
              <tr>
    {brandNames.map(brand => (
      <React.Fragment key={brand}>
        <th>Product</th>
        <th>Image</th>
        <th style={{ borderRight: "1px solid #000" }}>Price</th>
      </React.Fragment>
    ))}
  </tr>
            </thead>
            <tbody>
              {Math.max(...brandNames.map(brand => groupedProducts[brand].length)) > 0 &&
                [...Array(Math.max(...brandNames.map(brand => groupedProducts[brand].length)))].map((_, index) => (
                  <tr key={index} style={{ borderBottom: "3px solid #000" }}>
                    {brandNames.map(brand => (
                      groupedProducts[brand][index] && (
                        <React.Fragment key={brand} >
                          <td>
                            <div>{groupedProducts[brand][index].product}</div>
                            <div>{groupedProducts[brand][index].productCode}</div>
                          </td>
                          <td>
                            <img
                              src={groupedProducts[brand][index].image ? `http://miappserver.co.in:5086${groupedProducts[brand][index].image}` : 'https://media.istockphoto.com/id/182691828/photo/bathroom-faucet.jpg?s=612x612&w=0&k=20&c=4erUeHXd06v50a9z6IPxx3LqG7oO6R2cig9hSaLPalI='}
                              alt={brand}
                              className="product-image"
                            />
                          </td>
                          <td style={{ borderRight: "1px solid #000" }}>
                            <div>MRP:<span style={{color:"rgb(13, 119, 142)"}}>{groupedProducts[brand][index].mrp}</span> </div>
                            <div>Discount: <span style={{color:"rgb(13, 119, 142)"}}>{groupedProducts[brand][index].discount}</span></div>
                            <div>Sale Price:<span style={{color:"rgb(13, 119, 142)"}}>{groupedProducts[brand][index].salePrice}</span> </div>
                          </td>
                        </React.Fragment>
                      )
                    ))}
                  </tr  >
                ))}
                {/* Add a new row for total prices */}
                <tr style={{ borderBottom: "4px solid black" }}>
                  {brandNames.map(brand => (
                    groupedProducts[brand].length > 0 && (
                      <td key={brand} colSpan="3" style={{ fontWeight: 'bold', borderRight: '1px solid #000' }}>
                        Total:₹ {groupedProducts[brand].reduce((total, product) => total + parseFloat(product.salePrice || 0), 0).toFixed(2)}
                      </td>
                      
                    )
                  ))}
                </tr>
                
           
            </tbody>
          </table>
        </div>
      </div>

    </>
  );
};

export default ComparisionReport;
